export default {
    methods: {
        //新增一个编辑框,注意每次增删需要修改 mdContentList，picMap，contentTitle 三个内容，还需要注意注意
        addContent() {
            if (this.hb.mdContentList == null) {
                this.hb.mdContentList = [];
            }
            this.hb.mdContentList.push("");
            this.hb.contentTitle.push({ content: "", key: "", url: "", name: "" });
            this.hb.attributeList.push({
                author: "",
                dynasty: "",
                contentSource: "",
                backgroudUrl: "",
                backgroudKey: "",
                musicKey: "",
                musicUrl: "",
                contentDivList: []
            });
            this.hb.imageTag.push([]);
            this.hb.audioTag.push([]);
            this.hb.picMap[this.hb.mdContentList.length - 1] = [];
            this.hb.tagMap[this.hb.mdContentList.length - 1] = [];
            this.currentIndex = this.hb.mdContentList.length - 1;
        },
        // 删除一个编辑框
        removeContent(index) {
            if (this.currentIndex >= index) {
                this.currentIndex--;
            }
            for (let i = index; i < this.hb.mdContentList.length - 1; i++) {
                this.hb.picMap[i] = this.hb.picMap[i + 1];
                this.hb.tagMap[i] = this.hb.tagMap[i + 1];
            }
            this.hb.picMap[this.hb.mdContentList.length - 1] = undefined;
            this.hb.tagMap[this.hb.mdContentList.length - 1] = undefined;
            this.hb.mdContentList.splice(index, 1);
            this.hb.contentTitle.splice(index, 1);
            this.hb.imageTag.splice(index, 1);
            this.hb.attributeList.splice(index, 1);
            this.hb.audioTag.splice(index, 1);
        },
    }
}