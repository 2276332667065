function wordCn(str) {
    let strCn = str.match(/[\u4e00-\u9fa5]/g);
    if (strCn instanceof Array) {
        // console.log(strCn)
        return strCn.length;
    }
    return 0
}
function wordEn(str) {
    str = str.replace(/[\u4e00-\u9fa5，。！；？：《》【】（）……、‘’“”]/g, ' ')
    let strEn = str.split(' ').filter(ele => /[a-zA-Z]/.test(ele))
    if (strEn instanceof Array) {
        // console.log(strEn)
        return strEn.length;
    }
    return 0;
}
export function countString(str) {
    let numCn = wordCn(str)
    let numEn = wordEn(str)
    return { numCn, numEn }
}
export function countHtml(html) {
    let div = document.createElement('div')
    div.innerHTML = html;

    div.querySelectorAll('figure').forEach(ele => {
        div.removeChild(ele)
    })
    div.querySelectorAll('p').forEach(p => {
        p.innerHTML = p.innerHTML + ' '
    })
    return countString(div.textContent)

}