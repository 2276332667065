function getImgmarkSrc() {
    return '/images/img-mark.jpg'
}
/**
 * 解析html，返回里面的图片和标记等信息
 * @param {String} html 
 * @param {Array} oldImgList
 */
export function resolveHtml(html, oldImgList = []) {
    const div = document.createElement('div');
    div.innerHTML = html;
    let figures = div.getElementsByTagName('figure')
    let res = [];
    for (let fig of figures) {
        let img = fig.querySelector('img');
        let caption = fig.querySelector('figcaption');
        caption = caption ? caption.innerText.replace(/\s/g, '') : '';
        /**
         * alt 是所有的关键：图片标记 或 图片标记# 或 图片标记#AkjXy2t  三种格式完全不一样。
         * 图片标记 表示这是一个标记
         * 图片标记#  表示这是一个本地图片（标记已被替换，但暂未上传）
         * 图片标记#AkjXy2t 表示这是一个线上图片
         */
        let alt = img.alt ?? '';
        // 修改：目前只有一种图片标记路径了
        let src = img.getAttribute('src')
        let url = src === getImgmarkSrc() ? '' : src
        let key = alt.split('#')[1] || ''
        let existImgObj = oldImgList.find(item => url && (item.url === url))

        if (existImgObj) {
            existImgObj.caption = caption;
            res.push(existImgObj)
        } else {
            res.push({ caption, alt, url, key })
        }
    }
    return res;
}

/**
 * 
 * @param {String} html 
 * @param {Array} imgList 
 * @param {Array<number>} inds 
 */
export async function updateHtmlByImg(html, imgList, inds = null) {
    const div = document.createElement('div');
    div.innerHTML = html;
    let figures = div.getElementsByTagName('figure')
    function* indexIterator() {
        for (let cur = 0; cur < (inds ? inds.length : imgList.length); cur++) {
            yield inds ? inds[cur] : cur
        }
    }
    for (let i of indexIterator()) {
        let { url, alt, key } = imgList[i]
        let Img = figures.item(i).querySelector('img')

        alt = (alt ?? '').split('#')[0]
        if (url) { // old是标记
            alt += '#' + key
        } else {
            url = getImgmarkSrc()
        }
        imgList[i].alt = alt
        Img.setAttribute('alt', alt)
        Img.setAttribute('src', url)
    }
    return div.innerHTML;

}