<template>
  <div>
    <Input
      class="subtitle"
      v-model="hb.contentTitle[currentIndex].content"
      :disabled="true"
      placeholder="请输入文章标题"
    />
    <Input
      class="subtitle"
      style="margin-left: 2.6em"
      v-if="
        'cnView' === hb.contentType &&
        ('cnViewProse' === hb.category || 'cnViewModernPoetry' === hb.category)
      "
      v-model="hb.attributeList[currentIndex].contentSource"
      :disabled="true"
      placeholder="选自 / 来源"
    />
    <Input
      class="subtitle"
      style="margin-left: 2.6em"
      v-if="'cnView' === hb.contentType && 'cnViewAphorism' === hb.category"
      v-model="hb.attributeList[currentIndex].author"
      :disabled="true"
      placeholder="作者"
    />
    <div @click="handleClickFromPreviewEditor">
      <HbPreview
        :hb="hb"
        :showImgCaption="true"
        ref="preview"
        class="previeweditable"
      />
    </div>

    <div class="flex" style="align-items: center">
      <div>文章标题图片：</div>
      <ImgSelect
        :imgHolders="[hb.contentTitle[currentIndex]]"
        :config="{ file: 'file', hiddeSelectWhenFull: true }"
        @imgsChange="titleImgChange"
      />
      <div style="width: 280px"></div>
    </div>
    <div>
      <UploadImg4View
        v-for="(cnt, ind) in hb.mdContentList"
        :key="ind"
        v-show="currentIndex === ind"
        :imgList="hb.picMap[ind]"
        @imgsChange="imgChange"
      />
    </div>
  </div>
</template>

<script>
import { countHtml } from "../countService";
import hbMixin from "../hbMixin";

import UploadImg4View from "./UploadImg4View";
import * as Service from "./ResovleHtmlWithImgs";
function getImgMark(len) {
  let imgMark = `<figure class="image">
    <img class="preview-view-img" src="/images/img-mark.jpg" alt="图片标记">
    <figcaption style="text-align: center;background: #eee;">${
      len + 1
    }</figcaption>
  </figure>`;
  let div = document.createElement("div");
  div.innerHTML = imgMark;
  return div.firstElementChild;
}
export default {
  props: {
    hb: {},
  },
  mixins: [hbMixin],
  components: { UploadImg4View },
  data() {
    return {
      contrastDataList: [],
      updatingByImg: false,
      imgList: [],
    };
  },
  methods: {
    emitChange() {
      this.$emit("change");
    },
    /**
     * html 内容变了
     */
    changeContent(ind) {
      this.emitChange();
      if (this.updatingByImg) {
        this.updatingByImg = false;
        return;
      }
      let { numCn, numEn } = countHtml(this.hb.mdContentList[ind]);
      this.$set(this.hb.numCns, ind, numCn);
      this.$set(this.hb.numEns, ind, numEn);

      /**
       * html自身改变了，要由html解析更新picMap对应的图片列表
       */
      this.hb.picMap[ind] = Service.resolveHtml(
        this.hb.mdContentList[ind],
        this.hb.picMap[ind]
      );
    },
    titleImgChange() {
      this.emitChange();
      this.$eventBus.$emit("hbChange");
    },
    /**
     * 图片改变了，要同步更新html内容
     */
    imgChange(inds) {
      Service.updateHtmlByImg(
        this.hb.mdContentList[this.currentIndex],
        this.hb.picMap[this.currentIndex],
        inds
      ).then((content) => {
        this.hb.mdContentList[this.currentIndex] = content;

        // 内容锁定，没有使用编辑器，所以需要手动触发change事件，并更新中间的预览
        this.emitChange();
        this.$eventBus.$emit("hbChange");
      });
    },
    _afterModifyHtml(ind) {
      let str = this.$refs.preview.$el.lastElementChild.innerHTML;
      let ind1 = str.indexOf("<figure");
      let ind2 = str.indexOf("<p");
      str = str.substring(
        Math.min(ind1 < 0 ? str.length : ind1, ind2 < 0 ? str.length : ind2)
      );
      this.hb.mdContentList[ind] = str;
      this.updatingByImg = false;
      this.changeContent(ind);
    },

    handleClickFromPreviewEditor(e) {
      if (/^p$/i.test(e.target.tagName)) {
        let site = "after";
        this.$Modal.confirm({
          title: "选择插入图片标记的位置",
          render: (h) => {
            return h(
              "RadioGroup",
              {
                props: {
                  value: site,
                },
                on: {
                  input: (val) => {
                    site = val;
                  },
                },
              },
              [
                h(
                  "Radio",
                  {
                    props: {
                      label: "before",
                    },
                  },
                  "段落前"
                ),
                h(
                  "Radio",
                  {
                    props: {
                      label: "after",
                    },
                  },
                  "段落后"
                ),
              ]
            );
          },
          onOk: () => {
            let imgHtml = getImgMark(this.hb.picMap[this.currentIndex].length);
            e.target.insertAdjacentElement(
              site === "before" ? "beforebegin" : "afterend",
              imgHtml
            );
            this._afterModifyHtml(this.currentIndex);
          },
        });
      } else if (/^figcaption$/i.test(e.target.tagName)) {
        let mdl = e.target.textContent;
        this.$Modal.confirm({
          title: "请输入新的图片标记名称",
          render: (h) =>
            h("Input", {
              props: {
                value: mdl,
              },
              on: {
                input: (v) => {
                  mdl = v;
                },
              },
            }),
          onOk: () => {
            if (mdl === e.target.textContent) {
              return;
            }
            e.target.textContent = mdl;
            this._afterModifyHtml(this.currentIndex);
          },
        });
      } else if (
        /^img$/i.test(e.target.tagName) &&
        /img-mark\.jpg$/.test(e.target.src)
      ) {
        this.$Modal.confirm({
          title: "确认删除图片标记吗？",
          onOk: () => {
            const delEle = e.target.parentElement;
            delEle.parentElement.removeChild(delEle);
            this._afterModifyHtml(this.currentIndex);
          },
        });
      }
    },
  },
  mounted() {
    this.currentIndex = 0;
  },
  watch: {
    hb: {
      handler() {
        if (!this.hb) {
          return;
        }

        if (this.hb.contentType === "cnView" && !this.hb.category) {
          // 中文绘本使，二级分类默认普通绘本：
          this.hb.category = "cnView";
        }
        this.$set(this.hb, "numCns", []);
        this.$set(this.hb, "numEns", []);
        for (let i = this.hb.mdContentList.length - 1; i >= 0; i--) {
          this.changeContent(i);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang='scss'>
.subtitle {
  width: calc(50% - 3em);
  margin-bottom: 1em;
}
::v-deep [disabled],
::v-deep .ivu-select-selection {
  color: unset;
}
.previeweditable {
  ::v-deep p:hover,
  ::v-deep img[src$="img-mark.jpg"]:hover,
  ::v-deep figcaption:hover {
    cursor: pointer;
    outline: 2px solid rgba(69, 154, 223, 0.8);
  }
  ::v-deep .right-seg {
    overflow-y: scroll;
  }
}
</style>