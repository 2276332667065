<template>
  <div>
    <ImgSelect
      :imgHolders="imgList"
      :config="{ matchBy: selectFn, file: 'file' }"
      :compress="{
        enable: (file) => file.size > minSize * 1024,
        maxWidth: compressMaxWidth,
        quality: compressQuality,
      }"
      @imgsChange="imgsChange"
    />
    <div class="up_tip">
      <p>
        上传组图
        <Icon type="ios-settings-outline" @click="setCompress" />
      </p>
      <p>1）支持单图/多图上传，格式；JPG，PNG</p>
      <p>
        2）封面图命名；01，单段内容单张图按依次序号；1，2，3...，单段内容多张图按序号+次序号，如；1.1，1.2，1.3...
      </p>
    </div>
    <Modal v-model="showSetCompress" footer-hide>
      <div>
        <span style="display: inline-block; width: 10em">压缩质量：</span>
        <InputNumber v-model="compressQuality" :min="0" :max="1" />
      </div>
      <div>
        <span style="display: inline-block; width: 10em">最大宽度：</span>
        <InputNumber v-model="compressMaxWidth" :min="10" :max="8000" />
      </div>
      <div>
        <span style="display: inline-block; width: 10em"
          >压缩起止大小（kb）</span
        >
        <InputNumber v-model="minSize" :min="0" :max="8000" />
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  props: {
    imgList: {
      type: Array,
    },
  },
  data() {
    return {
      compressQuality: 0.6,
      compressMaxWidth: 1600,
      minSize: 200,
      showSetCompress: false,
    };
  },
  methods: {
    selectFn(file, imgList) {
      let name = file.name;
      let ind = name.lastIndexOf(".");
      let type = name.substring(ind + 1);
      name = name.substring(0, ind).replace(/\s/g, "");
      for (let i = 0; i < imgList.length; i++) {
        if (imgList[i].caption == name) {
          return i;
        }
      }
      return -1;
    },
    imgsChange(inds) {
      this.$emit("imgsChange", inds);
    },
    setCompress() {
      this.showSetCompress = true;
    },
  },
};
</script>
 <style scoped>
</style>
